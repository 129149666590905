var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view" }, [
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("h1", { staticClass: "h1 h1--eliteTitles h-textCenter" }, [
            _vm._v("Beats"),
          ]),
          _c("div", { staticClass: "flexbox flexbox--center h-margin-40" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.closeArtistDropdown,
                    expression: "closeArtistDropdown",
                  },
                ],
                staticClass: "dropdown dropdown--customSize",
              },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "formInput formInput--fullWidth formInput--selectCustom",
                    on: {
                      click: function ($event) {
                        _vm.isArtistTypeDropdownOpen = true
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.selectedArtistType
                          ? _vm.selectedArtistType.name
                          : "All Artist Types"
                      )
                    ),
                    _c("icon", { attrs: { name: "arrow-down" } }),
                  ],
                  1
                ),
                _c(
                  "ul",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isArtistTypeDropdownOpen,
                        expression: "isArtistTypeDropdownOpen",
                      },
                    ],
                    staticClass: "dropdown__menu dropdown__menu--withInput",
                  },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "dropdown__item dropdown__item--sm",
                        on: {
                          click: function ($event) {
                            return _vm.setArtistType("")
                          },
                        },
                      },
                      [_vm._v("All Artist Types")]
                    ),
                    _vm._l(_vm.artistTypes, function (artist) {
                      return _c(
                        "li",
                        {
                          key: artist.id,
                          staticClass: "dropdown__item dropdown__item--sm",
                          class: {
                            isActive: _vm.selectedArtistType
                              ? artist.slug === _vm.selectedArtistType.slug
                              : null,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setArtistType(artist)
                            },
                          },
                        },
                        [_vm._v(_vm._s(artist.name))]
                      )
                    }),
                  ],
                  2
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.closeGenreDropdown,
                    expression: "closeGenreDropdown",
                  },
                ],
                staticClass: "dropdown dropdown--customSize",
              },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "formInput formInput--fullWidth formInput--selectCustom",
                    on: {
                      click: function ($event) {
                        _vm.isGenreDropdownOpen = true
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.selectedGenre
                          ? _vm.selectedGenre.name
                          : "All Genres"
                      )
                    ),
                    _c("icon", { attrs: { name: "arrow-down" } }),
                  ],
                  1
                ),
                _c(
                  "ul",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isGenreDropdownOpen,
                        expression: "isGenreDropdownOpen",
                      },
                    ],
                    staticClass: "dropdown__menu",
                  },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "dropdown__item dropdown__item--sm",
                        on: {
                          click: function ($event) {
                            return _vm.setGenre("")
                          },
                        },
                      },
                      [_vm._v("All Genre")]
                    ),
                    _vm._l(_vm.genres, function (genre) {
                      return _c(
                        "li",
                        {
                          key: genre.id,
                          staticClass: "dropdown__item dropdown__item--sm",
                          class: {
                            isActive: _vm.selectedGenre
                              ? genre.slug === _vm.selectedGenre.slug
                              : null,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setGenre(genre)
                            },
                          },
                        },
                        [_vm._v(_vm._s(genre.name))]
                      )
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]),
          _c("PBeatTable", {
            attrs: { beatPlaylist: _vm.beats },
            on: {
              fetchMoreData: function ($event) {
                return _vm.updateBeatsTable($event, _vm.beats.length)
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }